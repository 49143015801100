<pf-table
  [response]="questionsComputed()"
  [initialFilters]="initialFilter()"
  [isLazyMode]="true"
  noResultLabel="Pas de question"
  (onParametersChanged)="onParametersChangedHandler($event)"
  searchPlaceholder="Rechercher par énoncé"
  [rows]="LIMIT_ROW_TABLE"
  [showCaption]="true"
  [showSearch]="false"
  title="Liste des questions"
  dataKey="id"
  [columns]="columns()"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr [hidden]="isTableLoading()">
      <td tooltipPosition="top" [pTooltip]="getTooltipDescription(question)">
        {{ question.description | truncate: MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <app-thumbnail [media]="question.picture"></app-thumbnail>
      </td>
      <td>{{ renderList(question.themes) }}</td>
      <td>{{ renderList(question.categories) }}</td>
      <td>
        <app-question-status
          [status]="question.questionStatus"
        ></app-question-status>
      </td>
      <td class="icon-button">
        <ng-container
          *ngTemplateOutlet="templateActions; context: { $implicit: question }"
        >
        </ng-container>
      </td>
    </tr>
    @if (isTableLoading()) {
      <tr>
        @for (col of columns(); track col) {
          <td>
            <p-skeleton />
          </td>
        }
      </tr>
    }
  </ng-template>
</pf-table>
